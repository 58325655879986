import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Main, Stack } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import { PostBody, PostComments } from '@widgets/Post'
import CardPostInfo from '@elegantstack/flow-ui-components/src/Card/Card.Post.Info'
import CardPost from '@elegantstack/flow-ui-components/src/Card/Card.Post'
import PlacesWallet from '@elegantstack/flow-ui-components/src/Places/Places-Wallet'

const BestCryptoWallet = ({
  data: {
    bestCryptoWallet,
    bestCryptoWallets,
    tagCategoryPosts,
    tagPosts,
    categoryPosts
  },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]

  if (bestCryptoWallet.wallets) {
    bestCryptoWallet.wallets.map(wallet => {
      const walletSlug = wallet.wallet.slug
      wallet.wallet = bestCryptoWallets.nodes.find(
        item => item.slug === walletSlug
      )
      return wallet
    })
  }

  const { pageContext: { services = {}, siteUrl } = {} } = props
  bestCryptoWallet.location = props.location
  return (
    <Layout {...props}>
      <Seo {...bestCryptoWallet} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <CardPost {...bestCryptoWallet} omitExcerpt />
        </Main>
      </Stack>
      {bestCryptoWallet.wallets && (
        <Stack effectProps={{ effect: 'fadeInDown' }}>
          <PlacesWallet {...{ nodes: bestCryptoWallet.wallets }} />
        </Stack>
      )}
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          {bestCryptoWallet.wallets &&
            bestCryptoWallet.wallets.map(item => {
              return (
                <React.Fragment key={item.id}>
                  <CardComponent variant='paper-lg'>
                    {item.wallet.body && <PostBody {...item.wallet} isRank={true} />}
                  </CardComponent>
                  {(item.wallet.website ||
                    item.wallet.transactionFees ||
                    item.wallet.currenciesAvailable) && (
                    <CardComponent sx={{ mt: '3', p: '4' }}>
                      <CardPostInfo post={item.wallet} />
                    </CardComponent>
                  )}
                  <Divider space={3} />
                </React.Fragment>
              )
            })}

          {bestCryptoWallet.author && (
            <AuthorExpanded author={bestCryptoWallet.author} />
          )}

          {services.disqus && (
            <>
              <Divider />
              <PostComments {...bestCryptoWallet} />
            </>
          )}

          {bestCryptoWallet.category && (
            <>
              <Divider />
              <CardList
                nodes={relatedPosts}
                variant={['horizontal-md']}
                columns={[1, 2, 3, 3]}
                limit={6}
                title='Related Posts'
                distinct
              />
            </>
          )}

          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}

          {bestCryptoWallet.body && (
            <>
              <Divider space={1} />
              <CardComponent variant='paper-lg'>
                <PostBody {...bestCryptoWallet} />
              </CardComponent>
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default BestCryptoWallet
