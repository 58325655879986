import { graphql } from 'gatsby'
import BestCryptoWalletPage from '../containers/BestCryptoWallet'

export default BestCryptoWalletPage

export const pageQuery = graphql`
  query BestCryptoWalletPageQuery(
    $id: String!
#    $currencyId: [String!]!
    $previousId: String
    $nextId: String
  ) {
    bestCryptoWallet: bestCryptoWallet(id: { eq: $id }) {
      ...BestCryptoWalletInformation
      ...BestCryptoWalletThumbnailRegular
    }

    bestCryptoWallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
#        currency: { elemMatch: { id: { in: $currencyId } } }
      }
      sort: { fields: [rank], order: ASC }
      limit: 10000
    ) {
      nodes {
        ...WalletInformation
        ...WalletThumbnailRegular
        ...WalletLogo
      }
    }

    previous: bestCryptoWallet(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: bestCryptoWallet(id: { eq: $nextId }) {
      id
      title
    }
  }
`
